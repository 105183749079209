.tooltip-container {
  position: relative;
}
.tooltip-container:not(.disabled-full-width) {
  width: 100%;
}
.tooltip-container > *:not(.tooltip-label):not(.info-icon) {
  z-index: 1;
}
.tooltip-container:not(.disabled-full-width)
  > *:not(.tooltip-label):not(.info-icon) {
  width: 100%;
}
.tooltip-container.left .tooltip-label {
  left: 0;
}
.tooltip-container.right .tooltip-label {
  right: 0;
}
.tooltip-label {
  z-index: 2;
  position: absolute;
  padding: 8px;
  background: white;
  border: 1px solid black;
  top: -28px;
  border-radius: 6px;
  font-size: 12px;
  transition: transform 100ms ease-in-out;
  transform-origin: bottom;
  transform: scale(0);
  pointer-events: none;
}
.tooltip-container:hover:not(.info-icon-container) .tooltip-label,
.info-icon:hover + .tooltip-label {
  transform: scale(1);
}
.info-icon-container {
  display: flex;
  gap: 4px;
}
.info-icon-container .info-icon + .tooltip-label {
  right: 0;
}
.info-icon {
  cursor: help;
}
