body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  display: grid;
  grid-template-rows: 1fr auto;
}

#versionId {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 6px 8px 6px 6px;
  font-size: 11px;
  background-color: rgb(250, 250, 250);
  box-sizing: border-box;
}

ul li::marker {
  text-decoration: none;
  unicode-bidi: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ck-editor__editable_inline {
  min-height: 350px;
  margin-bottom: 30px;
}

#fullscreeneditor {
  top: 0;
  z-index: 1200;
}

div.ck.ck-content {
  margin-bottom: 0;
}

div.ck.ck-word-count {
  z-index: 1201;
  display: flex;
  justify-content: flex-end;
  color: rgba(0, 0, 0, 0.54);
  margin: 0px 0 16px 0;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border: 1px solid var(--ck-color-toolbar-border);
  border-top: none;
  padding: 10px 16px;
  background: #fafafa;
}

div.ck-word-count div:first-child {
  margin-right: 8px;
}

div.ck-word-count div:last-child {
  margin-left: 8px;
}

.d-flex {
  display: flex;
}
button.mg-bottom-5 {
  margin-bottom: 5px;
}

/* Note previews */
.app .note-preview {
  background-repeat: no-repeat;
  background-position: center;
  background-image: var(--note-image);
  background-size: cover;
  height: 150px;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px hsla(0, 0%, 0%, 0.3);
  min-width: 160px;
}

.app .note-preview .note-preview__name {
  padding: 10px;
  background: hsl(0, 0%, 100%);
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.app .note-preview .note-preview__price {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  background: hsl(346, 100%, 56%);
  padding: 6px 10px;
  min-width: 50px;
  text-align: center;
  color: hsl(0, 0%, 100%);
  text-transform: uppercase;
  font-size: 0.8em;
}

.app .note-preview .note-preview__add {
  display: none;
}

/* --- note list styles --------------------------------------------------- */

.app .app__note-list {
  margin-left: 20px;
  padding: 20px;
  min-width: 400px;
  border-left: 1px solid hsl(0, 0%, 87%);
}

.app .app__note-list h2 {
  margin-top: 10px;
}

.app .app__note-list ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 10px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.app .app__note-list .note-preview {
  opacity: 0.7;
}

.app .app__note-list .note-preview:hover {
  opacity: 1;
}

.app .app__note-list .note-preview:hover .note-preview__add {
  display: block;
}

.app .app__note-list .note-preview .note-preview__add {
  display: none;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 45%;
  left: 50%;

  border: 0;
  padding: 0;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  border-radius: 100px;
  background: hsl(0, 0%, 100%);
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 2px hsla(0, 0%, 0%, 0.3);
}

.app .app__note-list .note-preview .note-preview__add span {
  font-size: 25px;
  vertical-align: middle;
  color: hsl(0, 0%, 24%);
  line-height: 40px;
  display: inline-block;
}

.app .app__note-list .note-preview .note-preview__name {
  font-size: 10px;
}

.note-preview img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: auto;
}

/* --- In-editor note widget styles --------------------------------------------------- */

.app .ck-content .note {
  margin: 1em;
  animation: slideUp 0.3s ease;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(1em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Puede interesarte */
.ck-toolbar__items button:last-child .ck-button__label {
  display: inline !important;
  margin-left: 4px;
  /* font-weight: bold; */
}

#fullscreenoverlay header.MuiAppBar-positionFixed {
  display: none;
}
